import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "../components/hero"
import ThanksComponent from "../components/thank-you"
import BodyLanding from "../components/body-landing"
import TopBullets from "./screens/ease-lab-backlog/top-bullets"

const ThankYou = ({ location }) => {
	const firstName = (location.state) ? location.state.first_name : ""
	return (
		<main>
			<Header />
			<Hero title="Ease your lab backlog">
				<StaticImage
					width={1300}
					loading="eager"
					src="../assets/images/ease-lab-backlog/hero.jpg"
					className="restrictImage"
					alt="Doctors in lab"
				/>
			</Hero>
			<ThanksComponent first_name={firstName}>
				<TopBullets />
			</ThanksComponent>
			<BodyLanding landingPage="ease-lab-backlog" />
		</main>
	)
}

export default ThankYou

export const Head = () => (
	<Seo
	  title="Ease your lab backlog"
	  description=" Increase efficiencies and leverage the flexibility of portable, instrument-free PCR with Visby Medical."
	/>
  )
